import {
  createTheme,
  responsiveFontSizes,
  type ThemeOptions,
} from '@mui/material/styles';

export const lightTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      default: '#ddd',
    },
    primary: {
      light: '#cedcf2',
      main: '#a6b2c3',
      dark: '#4f6a93',
      contrastText: '#000',
    },
    secondary: {
      light: '#834bff',
      main: '#651fff',
      dark: '#4615b2',
      contrastText: '#000',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          marginBottom: 0,
          paddingBottom: '15vh',
        },
        a: {
          color: 'inherit',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
  },
};

let theme = createTheme(lightTheme);

theme = responsiveFontSizes(theme);

export default theme;
