import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from 'firebase/auth';
import config from '../config/getConfig';
import app from './app';

export const auth = getAuth(app);

if (config.useEmulators === true) {
  connectAuthEmulator(auth, 'http://localhost:9099');
}

export const loginWithEmail = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const registerWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

export const changePassword = async (password: string) => {
  if (!auth.currentUser) {
    return;
  }
  return await updatePassword(auth.currentUser, password);
};

export const logout = async () => {
  return signOut(auth);
};

export default auth;
