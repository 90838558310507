import { DateTime } from 'luxon';
import type { Configuration } from './getConfig.interfaces';

const getConfig = (): Configuration => {
  const configuration: Configuration = {
    environment: 'production',
    version:
      process.env.REACT_APP_CI_COMMIT_TAG ??
      `production-${DateTime.utc().toFormat('yyyyddmmHHMM')}`,
    firebaseConfig: {
      apiKey: 'AIzaSyBway5ZapaAz9PRQcs4b6OA0m40xPNvAbw',
      authDomain: 'valvuri.firebaseapp.com',
      projectId: 'valvuri',
      storageBucket: 'valvuri.firebasestorage.app',
      messagingSenderId: '230282198650',
      appId: '1:230282198650:web:8a57f9f6b4451ab77b45c3',
    },
    useEmulators: false,
    minPasswordLength: 8,
    apiUrl: '/api/v1/',
  };
  if (process.env.REACT_APP_CONFIG === 'development') {
    configuration.environment = 'development';
    configuration.useEmulators = true;
    configuration.version = `development-${DateTime.utc().toFormat('yyyyddmmHHMM')}`;
    configuration.apiUrl = 'http://127.0.0.1:5001/valvuri/europe-north1/v1-';
  }
  if (process.env.REACT_APP_CONFIG === 'staging') {
    configuration.environment = 'staging';
    configuration.version = `staging-${DateTime.utc().toFormat('yyyyddmmHHMM')}`;
  }
  return configuration;
};

const config: Configuration = getConfig();
export default config;
