import { CssBaseline, ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { SnackbarProvider } from 'notistack';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, type RouteProps } from 'wouter';
import LoadingSpinner from './components/LoadingSpinner';
import userStore from './stores/User';
import darkTheme from './themes/dark';
import lightTheme from './themes/light';

const Login = lazy(() => import('./pages/Login'));
const Profile = lazy(() => import('./pages/Profile'));
const Registration = lazy(() => import('./pages/Registration'));
const Frontpage = lazy(() => import('./pages/Frontpage'));
const CompanyPage = lazy(() => import('./pages/CompanyPage'));
const RegisterCompany = lazy(() => import('./pages/RegisterCompany'));

interface IPrivateRoute extends RouteProps {
  allowUnverified?: boolean;
}

const PrivateRoute = observer((props: IPrivateRoute) => {
  if (!userStore.loggedIn) {
    return (
      <Route path={props.path}>
        <Redirect to="/login" />
      </Route>
    );
  }
  // if (props.allowUnverified !== true) {
  //   return <Route {...props} component={Frontpage} />;
  // }
  return <Route {...props} />;
});

const App = observer(() => {
  const theme = userStore.profile?.theme === 'dark' ? darkTheme : lightTheme;

  if (!userStore.ready) {
    return <LoadingSpinner />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        variant="error"
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={6000}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/profile" component={Profile} />
            <Route path="/register" component={Registration} />
            <PrivateRoute
              path="/register-company"
              component={RegisterCompany}
            />
            <PrivateRoute path="/company/:id" component={CompanyPage} />
            <Route component={Frontpage} />
          </Switch>
        </Suspense>
      </SnackbarProvider>
    </ThemeProvider>
  );
});

export default App;
