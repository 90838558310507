import { doc, getDoc, setDoc } from 'firebase/firestore';
import {
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import { merge } from 'ts-deepmerge';
import auth, {
  changePassword,
  loginWithEmail,
  logout,
  registerWithEmailAndPassword,
} from '../firebase/auth';
import db from '../firebase/db';
import i18n from '../translation/i18n';
import { type IUserStore, type UserProfile } from './User.interfaces';

class UserStore implements IUserStore {
  authLoaded = false;
  uid: string | null = null;
  email: string = '';
  emailVerified: boolean = false;
  profile: UserProfile | null = null;

  constructor() {
    makeObservable(this, {
      authLoaded: observable,
      uid: observable,
      email: observable,
      profile: observable,
      ready: computed,
      loggedIn: computed,
    });

    makePersistable(this, {
      name: 'valvuriUser',
      storage: window.localStorage,
      properties: ['email', 'profile'],
    });

    auth.onAuthStateChanged((user) => {
      if (user) {
        runInAction(() => {
          this.authLoaded = true;
          this.uid = user.uid;
          this.email = user.email || '';
          this.emailVerified = user.emailVerified;
        });
        this.loadProfile();
      } else {
        runInAction(() => {
          this.authLoaded = true;
          this.uid = null;
          this.emailVerified = false;
        });
      }
    });

    reaction(
      () => this.profile?.language,
      (current, previous) => {
        if (current !== previous) {
          i18n.changeLanguage(current);
        }
      }
    );
  }

  get ready() {
    return isHydrated(this) && this.authLoaded;
  }

  get loggedIn() {
    return this.ready && this.uid !== null;
  }

  login = async (password: string) => {
    return loginWithEmail(this.email, password).then((userCredential) => {
      runInAction(() => {
        this.uid = userCredential.user.uid;
        this.email = userCredential.user.email as string;
      });
      return userCredential;
    });
  };

  logout = async () => {
    console.log('logging out!');
    runInAction(() => {
      this.profile = null;
    });
    return logout();
  };

  register = async (password: string) => {
    return registerWithEmailAndPassword(this.email, password).then(
      (userCredential) => {
        runInAction(() => {
          this.uid = userCredential.user.uid;
          this.email = userCredential.user.email as string;
          this.saveProfile();
        });
        return userCredential;
      }
    );
  };

  setEmail = (email: string) => {
    runInAction(() => {
      this.email = email;
    });
  };

  updatePassword = async (password: string) => {
    return await changePassword(password);
  };

  loadProfile = async () => {
    getDoc(doc(db, `user/${this.uid}`)).then((snapshot) => {
      runInAction(() => {
        const data = snapshot.data()?.profile as UserProfile;
        if (!data) {
          return;
        }
        this.profile = merge(this.profile ?? {}, data) as UserProfile;
      });
    });
  };

  updateProfile = async (data: Partial<UserProfile>) => {
    const newProfile = merge(this.profile ?? {}, data) as UserProfile;
    console.log(data);
    runInAction(() => {
      this.profile = newProfile;
    });
    return newProfile;
  };

  saveProfile = async () => {
    return setDoc(
      doc(db, `user/${this.uid}`),
      {
        profile: this.profile,
      },
      { merge: true }
    ).finally(() => {
      this.loadProfile();
    });
  };

  getToken = async () => {
    return (await auth.currentUser?.getIdToken()) ?? null;
  };
}

const userStore = new UserStore();
export default userStore;
